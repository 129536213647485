class FaqUtils {
    //validate faq answer, check if it's a string
    static validateFaqAnswer(faqAnswer) {
        if (typeof faqAnswer !== 'string') {
            throw new Error('faqAnswer must be a string');
        }
    }
    //let's check if the user left an empty line at  the bottom, this will be a html, so it looks like <p><br></p>
    static checkEmptyLineAtBottom(faqAnswer) {
        const regex = /<p><br><\/p>$/;
        return regex.test(faqAnswer);
    }

}

export default FaqUtils;